








































































































import { Component, Vue } from "vue-property-decorator";
import Refresh from "@/components/defaultLayout/util/Refresh.vue";
import { FilterType, GetHistoriesInterface, Obj } from "@/types/objectType";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import { UserListResponse, UserResponse } from "@/generated/adminapi";
import datetime from "@/plugins/haii/datetime";
import { DataOptions } from "vuetify";
import Pagination from "@/components/defaultLayout/util/Pagination.vue";
import ApiHelperClass from "@/plugins/haii/api-helper";

interface UserListTableDataInterface {
  userKey: string;
  displayName: string;
  birthDT: string;
  phone: string;
  gender: string;
}

@Component({
  components: {
    Refresh,
    TextField,
    Pagination,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class UserList extends Vue {
  private totalNum = 0;
  title = this.$_haiiName();

  headers = [
    { text: "#", align: "center", value: "index", sortable: false },
    {
      text: this.$_haiiText("userKey"),
      align: "center",
      value: "userKey",
      sortable: false,
    },
    {
      text: this.$_haiiText("userName"),
      value: "displayName",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("gender"),
      value: "gender",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("birthDT"),
      value: "birthDT",
      align: "center",
      sortable: false,
    },
    {
      text: this.$_haiiText("phone"),
      value: "phone",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("institutionName"),
      value: "companyName",
      sortable: false,
      align: "center",
    },
    {
      text: this.$_haiiText("couponCode"),
      value: "couponCode",
      sortable: false,
      align: "center",
    },
  ];

  private UserListResponse = {} as UserListResponse;

  dataOptions = {} as DataOptions;
  filter = {} as FilterType;
  companyList = [];

  readonly DEFAULT_FILTER: FilterType = {
    userKey: "",
    name: "",
    date: ["", ""],
    serverUrl: "",
    phone: "",
    code: "",
    companyName: "",
  };

  readonly DEFAULT_DATA_OPTIONS: Required<DataOptions> = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["date"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  get queryDecoded(): GetHistoriesInterface | null {
    const query = this.$_haii.util.queryDecode(
      this.$route.query[this.$_haiiName()],
      this.DEFAULT_FILTER,
      this.DEFAULT_DATA_OPTIONS
    );

    if (query instanceof Error) {
      console.warn(this.$_haiiName(), query);
      return null;
    }

    return query as Obj<GetHistoriesInterface>;
  }

  get tableData(): Array<UserListTableDataInterface> {
    if (this.UserListResponse.total === undefined) {
      let temp = [];
      for (let i = 0; i < 10; i++) {
        temp.push({
          index: "",
          userKey: "",
          displayName: "",
          birthDT: "",
          phone: "",
          gender: "",
          companyName: "",
          couponCode: "",
        });
      }
      this.totalNum = 10;
      return temp;
    }

    this.totalNum = this.UserListResponse.total;
    // histories가 없으면 에러 처리
    if (!this.UserListResponse.total) {
      this.$_errorMessage(this.$_haiiText("noData"));
      return [];
    }
    return this.UserListResponse.users?.map((value) => {
      return {
        ...value,
        gender:
          value.gender == "M"
            ? this.$_haiiText("male")
            : this.$_haiiText("female"),
        phone: value.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
        companyName: value.coupon?.admin.company.companyName
          ? value.coupon.admin.company.companyName
          : "N/A",
        couponCode: value.coupon?.code ? value.coupon.code : "N/A",
      };
    });
  }

  created(): void {
    this.$watch(`$route.query.${this.$_haiiName()}`, this.onRouteChanged, {
      deep: true,
      immediate: true,
    });
    this.getCompanyList();
    document.addEventListener("keydown", this.onEnterKeydown);
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.onEnterKeydown);
  }

  onEnterKeydown(ev: DocumentEventMap["keydown"]): void {
    if (ev.code !== "Enter") {
      return;
    }
    this.onSearch();
  }

  // 나중에 서버에서 데이터 받아오는 기능
  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);

      const data = await this.$_apiHelper().v1AdminListUser({
        filter: this.filter,
        dataOptions: this.dataOptions,
      });
      this.handleFetchData(data);
    } catch (error) {
      this.$_errorMessage(this.$_haiiText("noData"));
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: UserListResponse | string): void {
    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      this.$_errorMessage(data);
      return;
    }
    this.$_haii.analytics.readEvent(
      this.$_haiiName() + "_handleFetchData",
      null,
      ApiHelperClass.query({
        filter: this.filter,
        dataOptions: this.dataOptions,
      })
    );
    this.UserListResponse = data;
  }

  async onSearch(): Promise<void> {
    if (!this.validatePhone()) {
      return;
    }
    this.dataOptions.page = 1;
    await this.routeChange();
  }

  // 브라우저에서 url 복사 시에도 검색 결과 화면이 유지하기위해 설정
  async routeChange(ev?: Event | string): Promise<void> {
    //검색 옵션 base61 형태로 변환
    const query = this.$_haii.util.queryEncode(this.filter, this.dataOptions);

    // 기존 query 와 다르다면 $route.push 실행
    if (this.$route.query[this.$_haiiName()] !== query) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          [this.$_haiiName()]: query,
        },
      });
    }
  }

  async onSearchReset(): Promise<void> {
    this.filter = {};
    // 필터값이 없으면 undefined
    if (this.$route.query[this.$_haiiName()]) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          [this.$_haiiName()]: undefined,
        },
      });
    } else {
      await this.fetchData();
    }
  }

  async onRouteChanged(): Promise<void> {
    const query = this.queryDecoded;
    if (query) {
      this.filter = query.filter || this.DEFAULT_FILTER;
      this.dataOptions = query.dataOptions || this.DEFAULT_DATA_OPTIONS;
    }
    await this.fetchData();
  }

  moveUser(userResponse: UserResponse): void {
    this.$router.push(`/users/${userResponse.UUID}`);
  }

  validatePhone(): boolean {
    const regExp =
      /01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$|01([0|1|6|7|8|9])([0-9]{7,8})/;
    if (this.filter.phone && !regExp.test(this.filter.phone)) {
      this.$_errorMessage(this.$_haiiText("phoneFormatError"));
      return false;
    }
    return true;
  }

  // 테이블 item row 에 클래스를 부여하는 메서드
  for_hover(): string {
    return "for_hover";
  }

  onPaginationUpdate(value: Partial<DataOptions>): void {
    Object.assign(this.dataOptions, value);
  }

  async getCompanyList(): Promise<void> {
    const data = await this.$_apiHelper().v1AdminListCompaniesName();

    if (typeof data === "string") {
      return this.$_errorMessage(data);
    }

    data.companies?.forEach((value) => {
      this.companyList.push(value.companyName);
    });
  }
}
