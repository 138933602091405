















import { Component, Prop, Vue } from "vue-property-decorator";
import {
  HistoryLogListResponse,
  HistoryLogResponse,
} from "@/generated/adminapi";
import { HistoryLogResponseRE } from "@/components/defaultLayout/report/ResultSummary.vue";

@Component({
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class UserAlzguardResult extends Vue {
  @Prop() readonly UserUUID!: string;

  title = this.$_haiiName();
  HistoryLogListResponse = {} as HistoryLogListResponse;
  gameDataList = {} as { number: [HistoryLogResponse] };

  created(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);

      const data = await this.$_apiHelper().v1AdminRetrieveUserHistory(
        this.UserUUID
      );

      this.handleFetchData(data);
    } catch (e) {
      this.$_errorMessage(e);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: HistoryLogListResponse | string): void {
    try {
      if (typeof data == "string" || data.total == 0) {
        this.$_errorMessage(this.$_haiiText("noData"));
        setTimeout((): void => {
          this.$router.go(-1);
        }, 2000);
        return;
      }

      let gameSet = new Set();

      data.historyLogs.forEach((historyLogResponse: HistoryLogResponse) => {
        if (!this.summaryCheck(historyLogResponse)) return;
        gameSet.add(historyLogResponse.game.itype);
      });

      const gameList = [...gameSet];
      let gameDataList = Array.from(Array(gameSet.size), () => [0, []]);

      gameList.forEach((value: number, index) => {
        return (gameDataList[index][0] = value);
      });

      data.historyLogs.forEach((historyLogResponse: HistoryLogResponseRE) => {
        gameDataList.forEach((value, index) => {
          if (historyLogResponse.game.itype == value[0]) {
            const list = gameDataList[index][1] as Array<HistoryLogResponseRE>;
            list.push(historyLogResponse);
          }
        });
      });

      const tt = gameDataList[0][1] as Array<HistoryLogResponseRE>;
      // tt.push(gameData);

      this.gameDataList = Object.fromEntries(gameDataList);

      this.HistoryLogListResponse = data;
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  summaryCheck(historyLog: HistoryLogResponse): boolean {
    switch (historyLog.game?.itype) {
      case 8000:
        return true;
      case 8200:
        return true;
      case 8100:
        return true;
      case 8300:
        return true;
      case 8400:
        return true;
      case 8800:
        return true;
    }
    return false;
  }

  gameResultsSummary(historyLog: HistoryLogResponseRE) {
    const testDt = this.$_haii.datetime.toYYMMDD(historyLog.CTS);
    const testStartTM = historyLog.startTM;
    const testEndTM = historyLog.endTM;
    const gameStartTM = historyLog.detail[0].questionStartTime;
    const gameEndTM =
      historyLog.detail[historyLog.detail.length - 1].questionEndTime;
    const totalNumOfQuestion = historyLog.detail.length;

    const correctAnswerNum = (): number => {
      let num = 0;
      historyLog.detail.forEach((value) => {
        if (value.answerResult == "정답") num++;
      });
      return num;
    };

    const playTime = this.$_haii.datetime.timeToHHMMSS(
      historyLog.endTM,
      historyLog.startTM
    );

    const totalSeconds = () => {
      let totalSeconds = 0;
      historyLog.detail.forEach((value) => {
        const gap =
          this.$_haii.datetime.timeGapToSeconds(value.questionEndTime) -
          this.$_haii.datetime.timeGapToSeconds(value.questionStartTime);
        totalSeconds += gap;
      });
      return totalSeconds;
    };

    const averageResponseTime = () => {
      let averageTime = "";

      averageTime = this.$_haii.datetime.secondsToHHMMSS(
        totalSeconds() / totalNumOfQuestion
      );
      return averageTime;
    };

    return [
      {
        testDt,
        totalNumOfQuestion,
        correctAnswerNum: correctAnswerNum(),
        playTime,
        averageResponseTime: averageResponseTime(),
        testStartTM,
        testEndTM,
        gameStartTM,
        gameEndTM,
      },
    ];
  }
}
