























































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { GenericResponse, UserResponse } from "@/generated/adminapi";
import { DataOptions, DataTableHeader } from "vuetify";

@Component
export default class MergeDialog extends Vue {
  @Prop() userListResponse!: UserResponse[];
  @Prop() originUserInfo!: UserResponse;
  @VModel() dialog!: boolean;

  get tableData(): UserResponse[] {
    const tempList = [];
    if (this.userListResponse) {
      this.userListResponse.forEach((value, index) => {
        tempList.push({
          ...value,
          index: index + 1,
          gender: value.gender,
          phone: value.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
          edu: value.cist.edu ? value.cist.edu : "N/A",
          eduYear: value.cist.eduYear,
          kMMSETotal: value.cist.kMMSETotal,
          kMMSEPercent: value.cist.kMMSEPercent,
          cistTotal: value.cist.cistTotal,
          cistResult: value.cist.cistResult,
        });
      });
    }

    return tempList;
  }

  get width(): string {
    if (this.$vuetify.breakpoint.xsOnly) return "100vw";
    if (this.$vuetify.breakpoint.smOnly) return "85vw";
    else return "50vw";
  }

  get headers(): DataTableHeader[] {
    return [
      { text: "#", align: "center", value: "index", sortable: false },

      {
        text: this.$_haiiText("edu"),
        value: "edu",
        sortable: false,
        align: "center",
      },
      {
        text: this.$_haiiText("kMMSETotal"),
        value: "kMMSETotal",
        sortable: false,
        align: "center",
      },
      {
        text: this.$_haiiText("kMMSEPercent"),
        value: "kMMSEPercent",
        sortable: false,
        align: "center",
      },
      {
        text: this.$_haiiText("cistTotal"),
        value: "cistTotal",
        sortable: false,
        align: "center",
      },
      {
        text: this.$_haiiText("cistResult"),
        value: "cistResult",
        sortable: false,
        align: "center",
      },
    ];
  }

  dataOptions = {} as DataOptions;

  readonly DEFAULT_DATA_OPTIONS: Required<DataOptions> = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["date"],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  // 테이블 item row 에 클래스를 부여하는 메서드
  for_hover(): string {
    return "for_hover";
  }

  async onSave(): Promise<void> {
    try {
      const data = await this.$_apiHelper().v1AdminMergeUser(
        this.originUserInfo.UUID,
        this.userListResponse[0].userKey
      );
      await this.handleOnSaveData(data);
    } catch (e) {
      this.$_errorMessage(e);
    }
  }
  async handleOnSaveData(data: GenericResponse | string): Promise<void> {
    try {
      if (typeof data == "string") return this.$_errorMessage(data);

      if (
        this.$route.path != `/edit-userinfo/${this.userListResponse[0].userKey}`
      ) {
        await this.$router.push(
          `/edit-userinfo/${this.userListResponse[0].userKey}`
        );
      } else {
        this.dialog = false;
        this.$emit("editComplete");
      }
    } catch (e) {
      this.$_errorMessage(e);
    }
  }
}
