



















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { UserListResponse, UserResponse } from "@/generated/adminapi";
import TextField from "@/components/defaultLayout/util/TextField.vue";
import DatePicker from "@/components/defaultLayout/util/DatePicker.vue";
import { isUserResponse } from "@/types/typeGuard";
import MergeDialog from "@/components/defaultLayout/userManagement/MergeDialog.vue";

@Component({
  components: {
    MergeDialog,
    TextField,
    DatePicker,
  },
  metaInfo(this) {
    return {
      title: this.title,
    };
  },
})
export default class User extends Vue {
  @Prop() readonly UserUUID!: string;

  title = this.$_haiiName();
  originUserInfo = {} as UserResponse;
  userInfo = {} as UserResponse;
  editState = false;
  userListResponse = [] as UserResponse[];
  mergeDialog = false;
  testList = ["Alzguard", "Samitalk"];

  get phoneMaxLength(): number {
    const regExp = /([0-9]{10,11})/g;

    if (regExp.test(this.userInfo.phone)) {
      return 11;
    }
    return 13;
  }

  created(): void {
    this.fetchData();
    document.addEventListener("keydown", this.onEnterKeydown);
  }

  onEnterKeydown(ev: DocumentEventMap["keydown"]): void {
    if (ev.code !== "Enter") {
      return;
    }
    this.onModifyUser();
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.onEnterKeydown);
  }

  async fetchData(): Promise<void> {
    try {
      this.$_changeLoadingState(true);

      const data = await this.$_apiHelper().v1AdminRetrieveUser(this.UserUUID);

      this.handleFetchData(data);
    } catch (e) {
      this.$_errorMessage(e);
    } finally {
      this.$_changeLoadingState(false);
    }
  }

  handleFetchData(data: UserResponse | string): void {
    if (typeof data == "string") {
      this.$_haii.analytics.errorEvent(data);
      this.$_errorMessage(this.$_haiiText("noData"));
      setTimeout((): void => {
        this.$router.go(-1);
      }, 2000);
      return;
    }
    this.originUserInfo = {
      ...data,
      phone: data.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
    };

    this.userInfo = {
      ...data,
      phone: data.phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
    };
  }

  validatePhone(): boolean {
    const regExp =
      /01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$|([0-9]{10,11})/g;

    if (!regExp.test(this.userInfo.phone)) {
      this.$_errorMessage(this.$_haiiText("phoneFormatError"));
      return false;
    }
    return true;
  }

  async onModifyUser(): Promise<void> {
    try {
      if (!this.validatePhone()) return;

      const data = await this.$_apiHelper().v1AdminModifyUser(
        this.userInfo.UUID,
        {
          displayName: this.userInfo.displayName,
          gender: this.userInfo.gender,
          birthDT: this.userInfo.birthDT,
          phone: this.userInfo.phone.replaceAll("-", ""),
          couponUUID: this.userInfo.coupon.UUID,
        }
      );

      this.handleModifyUserData(data);
    } catch (e) {
      this.$_errorMessage(e);
    }
  }

  handleModifyUserData(data: UserResponse | UserListResponse | string): void {
    if (typeof data === "string") {
      this.$_haii.analytics.errorEvent(data);
      this.$_errorMessage(data);
      return;
    }

    // UserResponse 가 리턴되면 매칭된 유저 없음
    // UserListResponse 가 리턴되면 매칭된 유저 존재
    if (isUserResponse(data)) {
      this.editState = false;
      this.$_haii.analytics.updateEvent(
        this.$_haiiName() + "_handleFetchData",
        this.originUserInfo,
        this.userInfo
      );

      this.$_toastMessage("success", this.$_haiiText("editSuccess"));
      return;
    } else {
      this.userListResponse = data.users;
      this.mergeDialog = true;
    }
  }
}
